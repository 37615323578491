<template>
    <!-- Mobile menu -->
    <TransitionRoot
        as="template"
        :show="open"
    >
        <Dialog
            as="div"
            class="fixed inset-0 z-40 flex lg:hidden"
            @close="open = false"
        >
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <DialogOverlay class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-x-0"
                leave-to="-translate-x-full"
            >
                <div class="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
                    <div class="flex px-4 pt-5 pb-2">
                        <button
                            type="button"
                            class="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                            @click="open = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <XIcon
                                class="w-6 h-6"
                                aria-hidden="true"
                            />
                        </button>
                    </div>

                    <!-- Links -->
                    <div class="px-4 py-6 mt-2 space-y-6">
                        <div
                            v-if="project.settings?.website?.active"
                            class="flow-root"
                        >
                            <Link
                                :href="route('project.home', project.domain)"
                                class="block p-2 -m-2 font-medium text-gray-900"
                            >{{ t('homepage') }}</Link>
                        </div>

                        <div
                            v-else-if="project.settings?.links?.homepage"
                            class="flow-root"
                        >
                            <a :href="project.settings?.links?.homepage">{{ t('homepage') }}</a>
                        </div>

                        <div class="flow-root">
                            <Link
                                :href="route('project.menu', project.domain)"
                                class="block p-2 -m-2 font-medium text-gray-900"
                            >{{ t('menu') }}</Link>
                        </div>

                        <div
                            v-if="project.settings.bookings.active"
                            class="flow-root"
                        >
                            <Link
                                :href="route('project.booking.index', project.domain)"
                                class="block p-2 -m-2 font-medium text-gray-900"
                            >{{ t('book-table') }}</Link>
                        </div>
                    </div>

                    <div
                        class="flex px-4 py-6 border-t border-gray-200 gap-x-6"
                        v-if="project.settings?.links?.facebook || project.settings?.links?.instagram || project.settings?.links?.tripadvisor"
                    >
                        <a
                            v-if="project.settings?.links?.facebook"
                            :href="project.settings.links.facebook"
                            target="_blank"
                            class="text-gray-400 hover:text-gray-500"
                        >
                            <span class="sr-only">Facebook</span>
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>

                        <a
                            v-if="project.settings?.links?.instagram"
                            :href="project.settings.links.instagram"
                            target="_blank"
                            class="text-gray-400 hover:text-gray-500"
                        >
                            <span class="sr-only">Instagram</span>
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>

                        <a
                            v-if="project.settings?.links?.tripadvisor"
                            :href="project.settings.links.tripadvisor"
                            target="_blank"
                            class="text-gray-400 hover:text-gray-500"
                        >
                            <span class="sr-only">TripAdvisor</span>
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 64 64"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M39.1 13.516a50.1 50.1 0 0 1 5.3 1.209 32.624 32.624 0 0 1 8.492 3.929 1.435 1.435 0 0 0 .7.2h10.189v.1a4.39 4.39 0 0 0-.4.705 16.853 16.853 0 0 0-2.5 5.239.477.477 0 0 0 .1.6 15.734 15.734 0 0 1 2.5 13.3 15.315 15.315 0 0 1-7.094 9.772 15.97 15.97 0 0 1-8.487 2.422 15.691 15.691 0 0 1-3.8-.5 16.211 16.211 0 0 1-7.893-4.634 9.057 9.057 0 0 1-.9-1.007c-1.1 1.713-2.3 3.425-3.4 5.138-1.2-1.713-2.3-3.425-3.4-5.037-.1 0-.1 0-.1.1l-.1.1a15.544 15.544 0 0 1-9.891 5.641 14.656 14.656 0 0 1-6.594-.4 15.314 15.314 0 0 1-7.793-4.936 15.784 15.784 0 0 1-3.8-8.16 13.951 13.951 0 0 1 .3-6.347 13.547 13.547 0 0 1 2.4-5.339.76.76 0 0 0 .1-.5 21.114 21.114 0 0 0-2.2-4.836 7.687 7.687 0 0 0-.8-1.108v-.1h9.9c.1 0 .3-.1.4-.1a34.036 34.036 0 0 1 7.194-3.526 50.8 50.8 0 0 1 5.6-1.511 33.995 33.995 0 0 1 5.6-.705 41.028 41.028 0 0 1 10.377.291zm-4 21.458a12.789 12.789 0 1 0 12.6-12.895 12.8 12.8 0 0 0-12.593 12.895zM15.924 22.079a12.846 12.846 0 1 0 12.788 12.895 12.706 12.706 0 0 0-12.788-12.895zm.7-3.324a15.746 15.746 0 0 1 10.091 4.231 16.211 16.211 0 0 1 5.2 9.772A16.351 16.351 0 0 1 37 23.087a15.491 15.491 0 0 1 10-4.231 36.237 36.237 0 0 0-14.187-3.022 38.507 38.507 0 0 0-16.19 2.921z"
                                ></path>
                                <path
                                    fill-rule="evenodd"
                                    d="M47.8 26.814a7.963 7.963 0 0 1 .3 15.917 7.962 7.962 0 1 1-.3-15.917zm5.2 7.959a5.146 5.146 0 1 0-10.291-.1 5.245 5.245 0 0 0 5.2 5.239 5.012 5.012 0 0 0 5.082-5.139zm-37.176-7.959a7.966 7.966 0 0 1 .4 15.917 7.971 7.971 0 0 1-8.293-7.959 7.756 7.756 0 0 1 7.893-7.958zm5.1 7.959a5.036 5.036 0 0 0-5.2-5.138 5.24 5.24 0 0 0 .1 10.477 5.316 5.316 0 0 0 5.095-5.339z"
                                ></path>
                                <path
                                    fill-rule="evenodd"
                                    d="M47.8 32.153a2.591 2.591 0 0 1 2.6 2.619 2.656 2.656 0 0 1-2.6 2.619 2.591 2.591 0 0 1-2.6-2.619 2.656 2.656 0 0 1 2.6-2.619z"
                                ></path>
                                <path
                                    fill-rule="evenodd"
                                    d="M15.724 32.153a2.677 2.677 0 0 1 2.7 2.619 2.763 2.763 0 0 1-2.7 2.72 2.67 2.67 0 1 1 0-5.339z"
                                ></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>

    <header class="relative z-10">
        <nav aria-label="Top">
            <div :class="['relative', isDark ? 'backdrop-blur-md backdrop-filter bg-opacity-10 bg-white' : 'bg-white', navbarBorder ? 'border-b border-gray-200' : '']">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div>
                        <div class="flex items-center justify-between h-16">
                            <!-- Logo (lg+) -->
                            <div class="hidden mr-8 lg:flex lg:items-center">
                                <Link :href="route('project.home', project.domain)">
                                <template v-if="logoUrl">
                                    <span class="sr-only">{{ project.name }}</span>
                                    <img
                                        class="w-auto h-8"
                                        :src="logoUrl"
                                        :alt="project.name"
                                    />
                                </template>

                                <span
                                    v-else
                                    :class="['text-sm font-bold tracking-tight xs:text-base sm:text-lg', isDark ? 'text-white' : 'text-gray-900']"
                                >{{ project.name }}</span>
                                </Link>
                            </div>

                            <div class="hidden h-full lg:flex">
                                <!-- Mega menus -->
                                <PopoverGroup>
                                    <div class="flex justify-center h-full space-x-8">
                                        <NavLink
                                            v-if="project.settings?.website?.active"
                                            :navbar-style="navbarStyle"
                                            :href="route('project.home', project.domain)"
                                            :active="route().current('project.home')"
                                        >{{ t('homepage') }}</NavLink>
                                        <NavLink
                                            v-else-if="project.settings?.links?.homepage"
                                            as="a"
                                            :navbar-style="navbarStyle"
                                            :href="project.settings?.links?.homepage"
                                        >{{ t('homepage') }}</NavLink>

                                        <NavLink
                                            :navbar-style="navbarStyle"
                                            :href="route('project.menu', project.domain)"
                                            :active="route().current('project.menu')"
                                        >{{ t('menu') }}</NavLink>
                                        <NavLink
                                            v-if="project.settings.bookings.active"
                                            :navbar-style="navbarStyle"
                                            :href="route('project.booking.index', project.domain)"
                                            :active="route().current('project.booking.index')"
                                        >{{ t('book-table') }}</NavLink>
                                    </div>
                                </PopoverGroup>
                            </div>

                            <!-- Mobile menu and search (lg-) -->
                            <div class="flex items-center flex-1 lg:hidden">
                                <button
                                    type="button"
                                    :class="['-ml-2 p-2 rounded-md', isDark ? 'text-gray-300' : 'text-gray-400']"
                                    @click="open = true"
                                >
                                    <span class="sr-only">Open menu</span>
                                    <MenuIcon
                                        class="w-6 h-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>

                            <!-- Logo (lg-) -->
                            <Link
                                :href="route('project.home', project.domain)"
                                class="lg:hidden"
                            >
                            <template v-if="logoUrl">
                                <span class="sr-only">{{ project.name }}</span>
                                <img
                                    class="w-auto h-8"
                                    :src="logoUrl"
                                    :alt="project.name"
                                />
                            </template>

                            <span
                                v-else
                                :class="['text-sm font-bold tracking-tight xs:text-base sm:text-lg', isDark ? 'text-white' : 'text-gray-900']"
                            >{{ project.name }}</span>
                            </Link>

                            <div class="flex items-center justify-end flex-1">
                                <CallWaiterButton
                                    v-if="$page.props.cart?.call_waiter && $page.props.cart?.has_place_session"
                                    v-slot="{ openModal }"
                                >
                                    <button
                                        type="button"
                                        :class="['p-1 rounded-full transition-colors focus:outline-none', isDark ? 'text-gray-300 hover:text-gray-100' : 'text-gray-500 hover:text-gray-800']"
                                        @click="openModal"
                                    >
                                        <span class="sr-only">{{ t('call-waiter.title') }}</span>
                                        <BellIcon
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </CallWaiterButton>

                                <Popover
                                    class="relative ml-4 lg:ml-6"
                                    v-slot="{ open }"
                                >
                                    <PopoverButton :class="[open ? (isDark ? 'text-white' : 'text-gray-800') : (isDark ? 'text-gray-300 hover:text-white' : 'text-gray-700 hover:text-gray-800'), 'group flex items-center transition-colors focus:outline-none']">
                                        <Flag
                                            class="mr-2"
                                            :code="$page.props.user.preferences.locale"
                                            :hasDropShadow="true"
                                            :has-border="true"
                                        />
                                        <span class="hidden text-sm font-medium sm:inline">{{ $page.props.user.preferences.locale_name }}</span>
                                        <span class="inline text-sm font-medium sm:hidden">{{ $page.props.user.preferences.locale.toUpperCase() }}</span>
                                        <span class="sr-only">, change language</span>
                                    </PopoverButton>

                                    <transition
                                        enter-active-class="transition duration-200 ease-out"
                                        enter-from-class="translate-y-1 opacity-0"
                                        enter-to-class="translate-y-0 opacity-100"
                                        leave-active-class="transition duration-150 ease-in"
                                        leave-from-class="translate-y-0 opacity-100"
                                        leave-to-class="translate-y-1 opacity-0"
                                    >
                                        <PopoverPanel class="absolute right-0 z-20 max-w-md px-2 mt-3 transform w-52 sm:px-0">
                                            <div class="overflow-hidden bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div class="px-2 pt-4 pb-2">
                                                    <p class="mb-2 ml-3 font-semibold text-gray-500 uppercase text-xxs">{{ t('choose_your_language') }}</p>

                                                    <a
                                                        v-for="item in project.locales"
                                                        :key="item.locale"
                                                        rel="alternate"
                                                        :hreflang="item.locale"
                                                        :href="item.url"
                                                        :class="['flex items-center px-3 py-2 text-sm rounded-md hover:bg-gray-100', $page.props.user.preferences.locale === item.locale ? 'font-medium text-gray-900' : 'text-gray-500']"
                                                    >
                                                        <Flag
                                                            class="flex-shrink-0 mr-2"
                                                            :code="item.locale"
                                                            :hasDropShadow="true"
                                                            :has-border="true"
                                                        />
                                                        {{ item.name_native }}
                                                    </a>
                                                </div>
                                            </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <!-- <span class="w-px h-6 mx-4 bg-gray-200 lg:mx-6" aria-hidden="true" />

                                <div v-if="$page.props.cart.active" class="flow-root">
                                    <Link :href="route('project.checkout', project.domain)" class="flex items-center p-2 -m-2 group">
                                        <ShoppingBagIcon class="flex-shrink-0 w-6 h-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                        <span class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{{ $page.props.cart.quantity.total }}</span>
                                        <span class="sr-only">items in cart, view bag</span>
                                    </Link>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>

</template>

<script>
import { computed, ref, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { Link, usePage } from '@inertiajs/vue3';
import Flag from '@Project/Components/Flag.vue';
import CountryFlag from 'vue-country-flag-next';
import NavLink from '@Project/Components/NavLink.vue';
import { BellIcon } from '@heroicons/vue/outline';

const AsyncCallWaiterButton = defineAsyncComponent(() =>
    import(/* webpackChunkName: "chunk-call_waiter_button" */ '@Project/Components/Menu/CallWaiterButton.vue')
);

import {
    Dialog,
    DialogOverlay,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import { MenuIcon, SearchIcon, ShoppingBagIcon, XIcon } from '@heroicons/vue/outline'

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        navbarStyle: {
            type: String,
            default: 'light',
        },

        navbarBorder: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        Link,
        Dialog,
        DialogOverlay,
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        TransitionChild,
        TransitionRoot,
        CallWaiterButton: AsyncCallWaiterButton,
        CountryFlag,
        Flag,
        NavLink,
        MenuIcon,
        SearchIcon,
        ShoppingBagIcon,
        XIcon,
        BellIcon,
    },

    setup(props) {
        const { t } = useI18n();

        const open = ref(false);
        const project = usePage().props.project;

        const isDark = computed(() => props.navbarStyle === 'dark');
        const logoUrl = computed(() => {
            if (props.navbarStyle === 'dark' && project.settings?.branding?.logo_light_image_url) {
                return project.settings?.branding?.logo_light_image_url;
            }

            return project.settings?.branding?.logo_image_url;
        });

        return {
            t,
            open,
            project,
            isDark,
            logoUrl,
        };
    },
})
</script>
